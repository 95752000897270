/*
*
* File JS componente Hero Slider Offers
*
*/

// IMPORT LIBRERIE ESTERNE
//--------------------------------------------------------------------//

import 'swiper/css/bundle';
import Swiper, { Navigation, Autoplay, EffectFade } from 'swiper';
Swiper.use([Navigation, Autoplay, EffectFade]);

//--------------------------------------------------------------------//


// IMPORT SCSS PER LA COMPILAZIONE
//--------------------------------------------------------------------//

import './hero-slider-offers.scss';

//--------------------------------------------------------------------//


// EXPORT COMPONENTE
//--------------------------------------------------------------------//

const heroSliderOffers = () => {
    let allSliderOffers = document.querySelectorAll('.hero-slider-offers');

    if (!allSliderOffers.length) return;

    let allSwipers = [];

    allSliderOffers.forEach((el, i) => {
        
        let autoplay = 5000;
        let intervalSlide = null;
        let progressBar = el.querySelector('.hero-slider-offers__progress');

        let btmSwiper = new Swiper(el.querySelector('.hero-slider-offers__btm'), {
            slidesPerView: 1,
            slidesPerView: 1,
            spaceBetween: 0,
            simulateTouch:false,
            allowTouchMove: false,
            speed: 1000,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
        });

        let topSwiper = new Swiper(el.querySelector('.hero-slider-offers__top'), {
            slidesPerView: 1.8,
            spaceBetween: 0,
            loop: true,
            loopedSlides: 3,
            centeredSlides: true,
            speed: 1000,
            breakpoints: {
                0: {
                    slidesPerView: 1.46
                },
                768: {
                    slidesPerView: 1.8
                }
            },
            autoplay: {
                delay: autoplay,
                disableOnInteraction: false
            },
            on:{
                progress: move,
                slideChange: (swiper) => {
                    btmSwiper.slideTo(swiper.realIndex);
                }
            }
        });
        
        function move() {
            let elem = progressBar; 
            let width = 1;
            let autoplayTime = autoplay / 100;

            clearInterval(intervalSlide);
            intervalSlide = setInterval(frame, autoplayTime);
            
            function frame() {
                if (width >= 100) {
                    clearInterval(intervalSlide);
                } else {
                    width++; 
                    elem.style.width = width + '%'; 
                }
            }
        }
    });
}

export default heroSliderOffers;

//--------------------------------------------------------------------//